import "core-js/modules/es.array.push.js";
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "AgentLogView"
};
import { onMounted, ref } from "vue";
import Apis_Agent from "@/apis/Apis_Agent";
import TableXsr from "@/components/Table/Table-User";
import ApisLang from "@/locales/Apis/ApisLang";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import LangCom from "@/utils/Lang/LangViews";
export default {
  __name: 'AgentLog',
  props: ['UserName'],
  setup(__props) {
    const props = __props;
    const LangLib = new LangCom('Agent', 'AgentLog');
    const GetLangTemplate = field => LangLib.GetTemplateLang(field);
    const GetLangSetup = field => LangLib.GetSetupLang(field);
    const LogArr = ref([]);
    const GetAgentLog = () => {
      Apis_Agent.getAgentLog(props.UserName, 1).then(res => {
        if (!Apis_Agent.checkResCode(res)) {
          return;
        }
        let data = res.data || [];
        console.log(data);
        LogArr.value.length = 0;
        for (let dataKey in data) {
          let temp = data[dataKey];
          let {
            time,
            object,
            type
          } = temp;
          LogArr.value.push({
            username: object || '-',
            label: time + '/' + GetTypeStr(type)
          });
          // console.log(object || '-')
        }
      });
    };

    onMounted(() => {
      GetAgentLog();
    });
    const TypeLang = ApisLang('Agent', 'getAgentLog_type');
    const GetTypeStr = type => {
      let int = Number(type);
      let str = TypeLang[int] || TypeLang['un'];
      // console.log(int,str)
      return str;
    };
    const TableEvent = () => {
      VantMsgLib.notify(4, GetLangSetup('TableEvent'));
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(TableXsr), {
        ViewData: LogArr.value,
        HeaderTitle: GetLangTemplate('HeaderTitle'),
        onTableEvent: TableEvent
      }, null, 8, ["ViewData", "HeaderTitle"])]);
    };
  }
};